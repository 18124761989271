<template>
  <div class="v-service">
    <div class="service-about">
        <div class="service-header">Нейрохирург</div>
        <div class="service-text">Нейрохирург — это врач, специализирующийся на диагностике и хирургическом лечении заболеваний и травм центральной и периферической нервной системы. Эта область медицины охватывает широкий спектр проблем, включая заболевания головного и спинного мозга, нервы и сосуды, питающие эти структуры.</div>
        <div class="service-text"><b>Когда обращаться к нейрохирургу:</b></div>
        <div class="service-text" style="text-align: left;">
            •	Головные боли: Если у вас есть сильные или постоянные головные боли, которые не поддаются лечению, это может быть признаком серьезной проблемы;<br>
            •	Травмы головы: После травмы головы, особенно если появились неврологические симптомы, такие как потеря сознания, спутанность сознания или судороги;<br>
            •	Неврологические симптомы: Паралич, онемение, слабость в конечностях, проблемы с координацией, зрением или речью;<br>
            •	Опухоли: Если у вас диагностирована опухоль головного или спинного мозга, необходима консультация нейрохирурга для оценки необходимости хирургического вмешательства;<br>
            •	Спинальные проблемы: Боли в спине, связанные с грыжами дисков или другими патологиями, которые не поддаются консервативному лечению;<br>
        </div>
        <div class="service-text">Обращение к нейрохирургу может быть важным шагом для диагностики и лечения серьезных заболеваний, связанных с нервной системой. Если у вас есть сомнения или симптомы, требующие внимания, рекомендуется проконсультироваться со специалистом.</div>
        <div class="service-text"><b>Отбор пациентов для консервативного лечения и оперативного лечения по программе ОМС</b></div>
        <div class="service-header">Услуги нейрохирурга</div>
        <div class="price-table">
            <div class="price-line"></div>
            <div v-for="service in services" :key="service">
                <div class="price-row">
                <div class="price-name">{{service.name}}</div>
                <div class="price-price">{{service.price}}</div>
                </div>
                <div class="price-line"></div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'v-gastr',
    props: {
    },
    mounted() {
        const meta = document.createElement("meta");
        meta.name = "description";
        meta.content = "Клиника здоровья нейрохирург";
        document.body.appendChild(meta);
    },
    data() {
        return {
            services: [
                {
                    name: 'Консультативный прием (первичный)',
                    price: '1 500 руб'
                },
                {
                    name: 'Консультативный прием (повторный в течение месяца)',
                    price: '1 300 руб'
                }
            ],
        }
    }
}
</script>

<style>
</style>
