<template>
  <div class="v-service">
    <div class="service-about">
        <div class="service-header">Сердечно-сосудистый хирург</div>
        <div class="service-text">Врач сердечно-сосудистый хирург — это специалист, занимающийся диагностикой и хирургическим лечением заболеваний сердца и сосудов. Эта область медицины охватывает широкий спектр заболеваний, включая коронарные болезни, пороки сердца, аневризмы сосудов и другие сердечно-сосудистые расстройства.</div>
        <div class="service-text"><b>Когда обращаться к сердечно-сосудистому хирургу:</b></div>
        <div class="service-text" style="text-align: left;">
            •	Боли в груди: Если у вас есть постоянные или сильные боли в груди, особенно если они сопровождаются одышкой, потливостью или тошнотой;<br>
            •	Сердечные заболевания: При наличии диагноза ишемической болезни сердца, сердечной недостаточности или других серьезных заболеваний сердца;<br>
            •	Пороки сердца: Если у вас диагностированы врожденные или приобретенные пороки сердца, которые могут требовать хирургического вмешательства;<br>
            •	Опухоли: Если у вас диагностирована опухоль головного или спинного мозга, необходима консультация нейрохирурга для оценки необходимости хирургического вмешательства;<br>
            •	Неэффективность консервативного лечения: Если медикаментозная терапия или другие методы лечения не приносят результатов и состояние пациента ухудшается;<br>
        </div>
        <div class="service-text">Обращение к сердечно-сосудистому хирургу может быть решающим шагом для диагностики и лечения серьезных заболеваний сердца и сосудов. Если у вас есть тревожные симптомы или предрасположенность к сердечно-сосудистым заболеваниям, рекомендуется проконсультироваться со специалистом.</div>
        <div class="service-header">Услуги сердечо-сосудистого хирурга</div>
        <div class="price-table">
            <div class="price-line"></div>
            <div v-for="service in services" :key="service">
                <div class="price-row">
                <div class="price-name">{{service.name}}</div>
                <div class="price-price">{{service.price}}</div>
                </div>
                <div class="price-line"></div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'v-gastr',
    props: {
    },
    mounted() {
        const meta = document.createElement("meta");
        meta.name = "description";
        meta.content = "Клиника здоровья сердечно-сосудистый хирург";
        document.body.appendChild(meta);
    },
    data() {
        return {
            services: [
                {
                    name: 'Консультативный прием (первичный)',
                    price: '1 800 руб'
                },
                {
                    name: 'Консультативный прием (повторный в течение месяца)',
                    price: '1 600 руб'
                }
            ],
        }
    }
}
</script>

<style>
</style>
